/**
 * FirerescueOne-specific variables
 *
 * Most of the variables are defined in <megaton-dir>/Ivelum.Shared/Tone.Web/
 * (the file imported below). Here are only the variables that need to be
 * overriden
 *
 * !! DON'T USE the "!default" keyword here
 */

// Theme color types
// Grays. Differ from the Style Guides, Approved here:
// https://praetoriangroup.atlassian.net/browse/PGH-228
$gray-dark: #232323;
$gray-medium: #878787;
$gray-light: #f2f2f2;

// colors

// Using the version from the latest design files
// (#ffce0c in the style guide, still used in hovered footer links)
$yellow: #febf01;

$red-link: #900;
$red-bright-hover: #cc001e;
$red-dark-2: #6a0707;
$red-bright: #be0808;
$red-brighter: #e90101;

// Specific elements colors

$image-bg: #7b0000;

// Global color for gray borders, hr-s, etc.
$border-color: $gray-medium;

// Global textual link color.
$link-color: $red-link;
$link-hover-color: $red-bright-hover;

// Special links (like .Link; .List--inline .List-link, etc).
$link-special-color: $red-link;
$link-special-hover-color: $red-bright-hover;

// Darker links (E.g., counters in Top-5)
$link-dark-color: $red-link;

/* Components
 ======================================================================= */

// Icon
$icon-highlight-color: $red-brighter;

// variables for cub widget
$cub-heading-bg: $red-link;
$cub-menu-color: $white;
$cub-menu-hover-color: $yellow;
$cub-menu-bg: $red-bright;
$cub-error-color: $link-color;
$cub-info-bg: $gray-medium;
$cub-error-bg: $red-bright-hover;

// buttons
$btn-bg: $link-color;
$btn-bg-hover: $link-hover-color;
$btn-icon-bg: $red-dark-2;
$btn-icon-bg-hover: $link-color;

// Box
$box-themed-bg: $red-link;
$box-themed-color: $white;

// Heading
$heading-sm-color: $gray-dark;

// Pagination
$pagination-bg-active: $link-color;
$pagination-border-active: $red-dark-2;

// TitleBar
$titlebar-bg: $gray-dark;
$titlebar-icon-bg: $link-color;
$titlebar-hover-bg: $link-color;
$titlebar-hover-icon-bg: $red-bright-hover;
$titlebar-is-include-badge-styles: false;

// "Editor's note"
$callout-ribbon-bg: $red-btn;
$callout-ribbon-back-bg: $red-dark-2;

// Modal
$modal-bg: $link-color;
$modal-icon-bg: $link-color;
$modal-hover-icon-bg: $link-hover-color;
// -warning- variables are used on the Modal-title--warning
$modal-warning-bg: $red-dark-2;
$modal-warning-icon-bg: $link-hover-color;
$modal-warning-hover-icon-bg: $link-color;

// Table
// <th>
$table-head-bg: $red-link;
$table-head-color: $white;
// <tr>
// highlighted
$table-row-highlight-bg: $red-dark-2;
$table-row-highlight-color: $white;

// $form-focus-color: $red-bright-hover;
$form-themed-bg: $gray-dark;
$form-themed-input-borders: $red-dark-2;
$form-subscription-bg: #1b0000;

// 500.html
$page500-h1-color: $red-link;

// Logo
// $footer-logo-width: 173px;

// Footer
$footer-top-bg: $red-link;
$footer-bottom-bg: $gray-dark;

$footer-color: #a2a2a2;
$footer-hover-color: $color-gold;

$footer-menu-color: $white;
$footer-menu-hover-color: $footer-hover-color;
$footer-menu-margin-lg: 17px;
$footer-menu-border-sm: #454545;
$footer-menu-bg-sm: $gray-dark;

$footer-menu-color-mobile: $footer-menu-color;
$footer-menu-active-color-mobile: $footer-menu-hover-color;
$footer-menu-hover-color-mobile: $footer-menu-hover-color;

// Header New
$header-new-bg: #7d1a19; // equals the bg fill image's background color
$header-new-top-ad-bg: $gray-dark;
$header-new-logo-bg: #972124;
$header-new-usermenu-bg: #972124;
$header-new-cub-menu-hover-color: $yellow;
$header-new-cub-menu-border: $red-bright-hover;
$header-new-top-link-hover-color: $yellow;

$header-new-bottom-bg-theme-image-opacity-option: 0.3;
$header-new-bottom-bg-theme-image-mobile: url("../images/backgrounds/header-bg-image-mobile.jpg");
$header-new-bottom-bg-fill-image: url("../images/backgrounds/header-bg-fill.jpg");
$header-new-bottom-bg-fill-pos: 99% 0;

$menu-new-item-hover-color: $yellow;

// Main Menu
$menu-submenus-bg: $red-dark-2;
$menu-submenu-bg: none;
$menu-submenu-border-color: $red-link;

$menu-submenu-link-l1-hover-bg: $red-bright;
$menu-submenu-link-l2-color: $black;
$menu-submenu-link-l2-hover-bg: $yellow;

$header-menu-toggle-bg: none;
$header-menu-toggle-active-color: #231f20;
$header-menu-toggle-active-bg: $yellow;

$header-rebrand-logo-width: 239px;

// Main Menu for Tablet Screens
$menu-submenu-bg-tablet: $red-dark-2;
$menu-submenu-brd-color-tablet: $red-brighter;

$menu-item-bg-tablet-hover: $red-bright;
$menu-item-brd-color-tablet: $red-dark-2;

// Headline
// 1) Special Report, Member Update
$headline-bg: $yellow;
$headline-title-bg: $gray-dark;
$headline-text-color: $gray-dark;
$headline-btn-bg-hover: $red-link;

// 2) Breaking News
$headline-bg-danger: $red-bright-hover;
$headline-title-bg-danger: $red-link;
$headline-btn-bg-hover-danger: $red-dark-2;
